import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {MdBarChart, MdHome, MdLock, MdOutlineRoom, MdPerson,} from "react-icons/md";

const routes = [
    {
        name: "Main Dashboard",
        layout: "/admin",
        path: "default",
        icon: <MdHome className="h-6 w-6"/>,
        component: <MainDashboard/>,
    },
    {
        name: "Cattles",
        layout: "/admin",
        path: "Cattles",
        icon: <MdOutlineRoom className="h-6 w-6"/>,
        component: <DataTables title={"All Cattles"} modalTitle={"Cattle"}/>,
        secondary: true,
    },
    {
        name: "Category",
        layout: "/admin",
        path: "Category",
        icon: <MdBarChart className="h-6 w-6"/>,
        component: <DataTables title={"All Categories"} modalTitle={"Category"}/>,
    },
    {
        name: "Products",
        layout: "/admin",
        path: "Products",
        icon: <MdPerson className="h-6 w-6"/>,
        component: <DataTables title={"All Products"} modalTitle={"Product"}/>,
    },
    {
        name: "Pending Orders",
        layout: "/admin",
        path: "Orders",
        icon: <MdPerson className="h-6 w-6"/>,
        component: <DataTables title={"Pending Orders"}/>,
    },
    {
        name: "Completed Orders",
        layout: "/admin",
        path: "Completed",
        icon: <MdPerson className="h-6 w-6"/>,
        component: <DataTables title={"Completed Orders"}/>,
    },
    {
        name: "Canceled Orders",
        layout: "/admin",
        path: "Canceled",
        icon: <MdPerson className="h-6 w-6"/>,
        component: <DataTables title={"Canceled Orders"}/>,
    },
    {
        name: "Sign In",
        layout: "/auth",
        path: "sign-in",
        icon: <MdLock className="h-6 w-6"/>,
        component: <SignIn/>,
    },
];
export default routes;
