import axios from "axios";
import {prefixUrl} from "./config";


// Users APIs
export const authenticateUser = async (data) => {
    try {
        const response = await axios.post(`${prefixUrl}/CheckAdminUser`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Category APIs
export const getCategories = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/Category`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getCategoriesById = async (categoryId) => {
    try {
        const response = await axios.get(`${prefixUrl}/Category/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const insertCategory = async (data) => {
    try {
        const response = await axios.post(`${prefixUrl}/Category`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const updateCategory = async (id, data) => {
    try {
        const response = await axios.put(`${prefixUrl}/Category/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteCategory = async (id) => {
    try {
        const response = await axios.delete(`${prefixUrl}/Category/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const totalCategories = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/TotalCategories`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// Products APIs
export const getProducts = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/Product`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getProductById = async (id) => {
    try {
        const response = await axios.get(`${prefixUrl}/Product/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const totalProducts = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/TotalProducts`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const insertProduct = async (data) => {
    try {
        const response = await axios.post(`${prefixUrl}/Product`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const updateProduct = async (data, productId) => {
    try {
        const response = await axios.put(`${prefixUrl}/Product/${productId}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteProduct = async (productId) => {
    try {
        const response = await axios.delete(`${prefixUrl}/Product/${productId}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// Cattles APIs
export const getCattles = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/Cattle`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const totalCattles = async () => {
    try {
        const respones = await axios.get(`${prefixUrl}/TotalCattles`);
        return respones.data;
    } catch (error) {
        console.error(error);
    }
};

export const insertCattles = async (data) => {
    try {
        const response = await axios.post(`${prefixUrl}/Cattle`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteCattles = async (cattleId) => {
    try {
        const response = await axios.delete(`${prefixUrl}/Cattle/${cattleId}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updateCattles = async (data, cattleId) => {
    try {
        const response = await axios.put(`${prefixUrl}/Cattle/${cattleId}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getCattleById = async (id) => {
    try {
        const response = await axios.get(`${prefixUrl}/Cattle/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};


// Cart APIs
export const getOrders = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/Cart`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getPendingOrder = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/FetchOrderedOrders`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}
export const getCompletedOrder = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/FetchCompletedOrders`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}
export const getCanceledOrder = async () => {
    try {
        const response = await axios.get(`${prefixUrl}/FetchCanceledOrders`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}
export const completedOrder = async (cartId) => {
    try {
        const response = await axios.put(`${prefixUrl}/CompleteOrder/${cartId}`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}
export const canceledProduct = async (cartId) => {
    try {
        const response = await axios.put(`${prefixUrl}/CancelOrder/${cartId}`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}