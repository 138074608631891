import ColumnsTable from "./components/ColumnsTable";
import {useNavigate} from "react-router-dom";
import {authenticate} from "../../../service/config";

const Tables = (props) => {
    const {title, modalTitle} = props;
    const navigate = useNavigate();

    authenticate(navigate)

    return (<div>
        <div className="mt-5 h-full grid-cols-1 gap-5 md:grid-cols-2">
            <ColumnsTable title={title} modalTitle={modalTitle}/>
        </div>
    </div>);
};

export default Tables;
