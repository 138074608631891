import {useState} from "react";
import {authenticateUser} from "../../service/api";
import {useNavigate} from "react-router-dom";

export default function SignIn() {
    const navigate = useNavigate()
    const [form, setForm] = useState({
        Email: '', Password: '',
    })

    const handleInput = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form, [name]: value
        })
    }

    const checkUser = async () => {
        const response = await authenticateUser(form);
        if (response) {
            localStorage.setItem('userId', response.id)
            navigate("/admin/default");
        }
    }

    return (<div
            className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Sign In
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your email and password to sign in!
                </p>

                <input type="text"
                       className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="Enter your Email: "
                       autoFocus={true}
                       name="Email"
                       value={form.Email}
                       onChange={handleInput}
                />

                <input type="password"
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="Enter your Password: "
                       name="Password"
                       value={form.Password}
                       onChange={handleInput}
                />

                <button
                    onClick={checkUser}
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">

                    Sign In
                </button>
            </div>
        </div>
    );
}
