import {IoMdHome} from "react-icons/io";
import {IoDocuments} from "react-icons/io5";
import {MdBarChart, MdDashboard} from "react-icons/md";
import {useEffect, useState} from "react";
import Widget from "components/widget/Widget";
import {totalCategories, totalCattles, totalProducts} from "service/api";
import {useNavigate} from "react-router-dom";
import {authenticate} from "../../../service/config";

const Dashboard = () => {
    const navigate = useNavigate();
    const [totalCattle, setTotalCattle] = useState(0);
    const [totalCategory, setTotalCategory] = useState(0);
    const [totalProduct, setTotalProduct] = useState(0);

    authenticate(navigate)

    // Fetching Total Categories
    const fetchTotalCategories = async () => {
        const data = await totalCategories();
        setTotalCategory(data);
    };

    // Fetching Total Products
    const fetchTotalProducts = async () => {
        const data = await totalProducts();
        setTotalProduct(data);
    };

    // Fetching Total Cattles
    const fetchTotalCattles = async () => {
        const data = await totalCattles();
        setTotalCattle(data);
    };

    useEffect(() => {

        fetchTotalCategories();
        fetchTotalProducts();
        fetchTotalCattles();
    }, []);

    return (<div>
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
            <Widget
                icon={<MdBarChart className="h-7 w-7"/>}
                title={"Earnings"}
                subtitle={"₹ 340.5"}
            />
            <Widget
                icon={<IoDocuments className="h-6 w-6"/>}
                title={"Spend this month"}
                subtitle={"₹ 642.39"}
            />
            <Widget
                icon={<MdBarChart className="h-7 w-7"/>}
                title={"Sales"}
                subtitle={"₹ 574.34"}
            />
            <Widget
                icon={<MdDashboard className="h-6 w-6"/>}
                title={"Total Cattles"}
                subtitle={totalCattle}
            />
            <Widget
                icon={<MdBarChart className="h-7 w-7"/>}
                title={"Total Categories"}
                subtitle={totalCategory}
            />
            <Widget
                icon={<IoMdHome className="h-6 w-6"/>}
                title={"Total Products"}
                subtitle={totalProduct}
            />
        </div>
    </div>);
};

export default Dashboard;
