import Card from "components/card";
import React, {useEffect, useState} from "react";
import {
    getCanceledOrder,
    getCategories,
    getCattles,
    getCompletedOrder,
    getPendingOrder,
    getProducts
} from "service/api";
import Modal from "./Modal";
import {authenticate, prefixUrl} from "service/config";
import {useNavigate} from "react-router-dom";

const ColumnsTable = (props) => {
    const {title, setModalTitle} = props;
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [products, setProduct] = useState([]);
    const [cattles, setCattle] = useState([]);
    const [pendingOrders, setPendingOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [canceledOrders, setCanceledOrders] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [productId, setProductId] = useState();
    const [cattleId, setCattleId] = useState();
    const [orderId, setOrderId] = useState();
    const [modalName, setModalName] = useState("");
    const [emptyModal,setEmptyModal] = useState(0);

    authenticate(navigate)
    // Fetching Categories
    const fetchCategoryData = async () => {
        try {
            const data = await getCategories();
            setCategories(data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetching Products
    const fetchProductsData = async () => {
        try {
            const data = await getProducts();
            setProduct(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    // Fetching Cattles
    const fetchCattlesData = async () => {
        try {
            const data = await getCattles();
            setCattle(data);
        } catch (error) {
            console.error("Error fetching cattles:", error);
        }
    };

    // Fetching Orders
    const fetchPendingOrdersData = async () => {
        try {
            const data = await getPendingOrder();
            setPendingOrders(data);
        } catch (error) {
            console.error("Error fetching orders", error);
        }
    }

    // Fetching Completed Orders
    const fetchCompletedOrdersData = async () => {
        try {
            const data = await getCompletedOrder();
            setCompletedOrders(data);
        } catch (error) {
            console.error("Error fetching orders", error);
        }
    }

    // Fetching Canceled Orders
    const fetchCanceledOrdersData = async () => {
        try {
            const data = await getCanceledOrder();
            setCanceledOrders(data);
        } catch (error) {
            console.error("Error fetching orders", error);
        }
    }

    // Modal Close
    const modalClose = () => {
        setModalOpen(false);
        fetchCategoryData();
        fetchProductsData();
        fetchCattlesData();
        fetchPendingOrdersData();
        fetchCompletedOrdersData();
        fetchCanceledOrdersData();
    };

    useEffect(() => {
        fetchCategoryData();
        fetchProductsData();
        fetchCattlesData();
        fetchPendingOrdersData();
        fetchCompletedOrdersData();
        fetchCanceledOrdersData();
    }, []);

    return (<>
        {title === "All Categories" && <Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </div>
                {/* <CardMenu /> */}
                <button
                    className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    onClick={() => {
                        setModalOpen(true);
                        setModalName("Category");
                        setCategoryId();
                    }}
                >
                    Add
                </button>
            </header>
            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
                <table className="w-full">
                    <thead>
                    <tr>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                #
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Title
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Description
                            </div>
                        </th>
                        <th
                            colSpan={2}
                            className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700"
                        >
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Action
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map((category, index) => (<tr key={index}>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {index + 1}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {category.title}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {category.description}
                        </td>
                        <td>
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setCategoryId(category.id);
                                    setModalName("Update Category");
                                }}
                            >
                                Update
                            </button>
                        </td>
                        <td>
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setCategoryId(category.id);
                                    setModalName("Delete Category");
                                }}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Card>}
        {title === "All Products" && (<Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </div>
                {/* <CardMenu /> */}
                <button
                    className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    onClick={() => {
                        setModalOpen(true);
                        setModalName("Products");
                        setCategoryId();
                    }}
                >
                    Add
                </button>
            </header>
            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
                <table className="w-full">
                    <thead>
                    <tr>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                #
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Name
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Description
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Price
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Image
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Category
                            </div>
                        </th>
                        <th
                            colSpan={2}
                            className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700"
                        >
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Action
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((product, index) => (<tr key={index}>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {index + 1}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {product.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {product.description}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {product.price}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            <img
                                src={prefixUrl + "/uploads/" + product.imageName}
                                alt={product.imageName}
                                height="60px"
                                width="60px"
                                style={{borderRadius: "5px"}}
                            />
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {product.category.title}
                        </td>
                        <td>
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setProductId(product.id);
                                    setCategoryId(product.categoryId);
                                    setModalName("Update Product");
                                }}
                            >
                                Update
                            </button>
                        </td>
                        <td>
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setProductId(product.id);
                                    setModalName("Delete Product");
                                }}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Card>)}
        {title === "All Cattles" && (<Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </div>
                {/* <CardMenu /> */}
                <button
                    className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    onClick={() => {
                        setModalOpen(true);
                        setModalName("Cattle");
                    }}
                >
                    Add
                </button>
            </header>
            <div className="mt-8 overflow-x-auto relative">
                <table className="w-full min-w-max">
                    <thead>
                    <tr>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                #
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Name
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Gender
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Tail Length
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Weight
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Height
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Length
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Color
                            </div>
                        </th>
                        <th colSpan={2}
                            className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Action
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {cattles.map((cattle, index) => (<tr key={index}>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {index + 1}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.gender}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.tailLength}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.weight}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.height}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.length}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {cattle.color}
                        </td>
                        <td>
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setCattleId(cattle.id);
                                    setModalName("Update Cattle");
                                }}
                            >
                                Update
                            </button>
                        </td>
                        <td>
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setCattleId(cattle.id);
                                    setModalName("Delete Cattle");
                                }}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Card>)}
        {title === "Pending Orders" && (<Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </div>
                {/* <CardMenu /> */}
            </header>
            <div className="relative overflow-x-auto mt-8">
                <table className="w-full min-w-max">
                    <thead>
                    <tr>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                #
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Username
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Address
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Number
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Product
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Price
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Quantity
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Date
                            </div>
                        </th>
                        <th colSpan={2}
                            className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Action
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {pendingOrders.map((order, index) => (<tr key={index}>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {index + 1}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.address}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.number}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.product.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.price}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.quantity}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.date}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setModalName("Complete Order");
                                    setOrderId(order.id)
                                }}
                            >
                                Complete
                            </button>
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            <button
                                className="block rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                    setModalOpen(true);
                                    setModalName("Cancel Order");
                                    setOrderId(order.id)
                                }}
                            >
                                Cancel
                            </button>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Card>)}
        {title === "Completed Orders" && (<Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </div>
                {/* <CardMenu /> */}
            </header>
            <div className="relative overflow-x-auto mt-8">
                <table className="w-full min-w-max">
                    <thead>
                    <tr>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                #
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Username
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Address
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Number
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Product
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Price
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Quantity
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Date
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {completedOrders.map((order, index) => (<tr key={index}>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {index + 1}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.address}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.number}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.product.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.price}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.quantity}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.date}
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Card>)}
        {title === "Canceled Orders" && (<Card extra={"w-full pb-10 p-4 h-full"}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    {title}
                </div>
                {/* <CardMenu /> */}
            </header>
            <div className="relative overflow-x-auto mt-8">
                <table className="w-full min-w-max">
                    <thead>
                    <tr>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                #
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Username
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Address
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Number
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Product
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Price
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Quantity
                            </div>
                        </th>
                        <th className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700">
                            <div
                                className="flex w-full justify-between pr-10 text-base tracking-wide text-gray-600">
                                Date
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {canceledOrders.map((order, index) => (<tr key={index}>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {index + 1}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.address}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.user.number}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.product.name}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.price}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.quantity}
                        </td>
                        <td className="pt-[14px] pb-[20px] text-sm font-bold text-navy-700 dark:text-white sm:text-[14px]">
                            {order.date}
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Card>)}

        <Modal
            title={modalName}
            modalOpen={modalOpen}
            setModalOpen={modalClose}
            categoryId={categoryId}
            productId={productId}
            cattleId={cattleId}
            orderId={orderId}
        />
    </>);
};

export default ColumnsTable;
