import React, {useEffect, useState} from "react";
import {
    canceledProduct,
    completedOrder,
    deleteCategory,
    deleteCattles,
    deleteProduct,
    getCategories,
    getCategoriesById,
    getCattleById,
    getProductById,
    insertCategory,
    insertCattles,
    insertProduct,
    updateCategory,
    updateCattles,
    updateProduct
} from "service/api";

const Modal = (props) => {
    const {title, categoryId, productId, cattleId, orderId, modalOpen, setModalOpen} = props;
    const [categories, setCategories] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);


    const [form, setForm] = useState({
        title: "", description: "",
    });

    const [productForm, setProductForm] = useState({
        name: "", description: "", price: "", imageName: "", categoryIdForProduct: "",
    });

    const [cattleForm, setCattleForm] = useState({
        name: "",
        gender: "",
        idNumber: "",
        dateOfBirth: "",
        weight: "",
        height: "",
        length: "",
        color: "",
        tailLength: "",
        hornLength: "",
        earLength: "",
        teeth: "",
        eyeColor: "",
        udderColor: "",
        milkCapacityInLiters: "",
        deliveryDate: "",
        motherSideHistory: "",
        fatherSideHistory: "",
        behaviour: "",
        habitOfEating: "",
        nature: "",
    });

    // To empty all forms
    const emptyForm = async () => {
        setForm({
            title: "", description: "",
        });

        setProductForm({
            name: "", description: "", price: "", imageName: "", categoryIdForProduct: "",
        });

        setCattleForm({
            name: "",
            gender: "",
            idNumber: "",
            dateOfBirth: "",
            weight: "",
            height: "",
            length: "",
            color: "",
            tailLength: "",
            hornLength: "",
            earLength: "",
            teeth: "",
            eyeColor: "",
            udderColor: "",
            milkCapacityInLiters: "",
            deliveryDate: "",
            motherSideHistory: "",
            fatherSideHistory: "",
            behaviour: "",
            habitOfEating: "",
            nature: "",
        });
    };

    // Handle Input Change for Category
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form, [name]: value,
        });
    };

    // Handle Input Change for Products
    const handleProductInputChange = (e) => {
        const {name, value, files} = e.target;

        if (name === 'imageName' && files.length > 0) {
            setSelectedFile(files[0]);
        } else {
            setProductForm({
                ...productForm, [name]: value,
            });
        }
    };


    // Handle Input Change for Cattle
    const handleCattleInputChange = (e) => {
        const {name, value} = e.target;
        setCattleForm({
            ...cattleForm, [name]: value,
        });
    };

    // Fetching Categories
    const fetchCategoryData = async () => {
        try {
            const data = await getCategories();
            setCategories(data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetching Category By ID
    const fetchCategoryAccId = async (categoryId) => {
        if (categoryId) {
            try {
                const data = await getCategoriesById(categoryId);
                setForm({
                    title: data.title, description: data.description,
                });
            } catch (error) {
                console.error("Error fetching Categories:", error);
            }
        }
    };
    const fetchCattleAccId = async (cattleId) => {
        if (cattleId) {
            try {
                const data = await getCattleById(cattleId);
                setCattleForm({
                    name: data.name,
                    gender: data.gender,
                    idNumber: data.idNumber,
                    dateOfBirth: data.dateOfBirth,
                    weight: data.weight,
                    height: data.height,
                    length: data.length,
                    color: data.color,
                    tailLength: data.tailLength,
                    hornLength: data.hornLength,
                    earLength: data.earLength,
                    teeth: data.teeth,
                    eyeColor: data.eyeColor,
                    udderColor: data.udderColor,
                    milkCapacityInLiters: data.milkCapacityInLiters,
                    deliveryDate: data.deliveryDate,
                    motherSideHistory: data.motherSideHistory,
                    fatherSideHistory: data.fatherSideHistory,
                    behaviour: data.behaviour,
                    habitOfEating: data.habitOfEating,
                    nature: data.nature,
                });
            } catch (error) {
                console.error("Error fetching cattle:", error);
            }
        }
    };

    // Fetching Product By ID
    const fetchProductAccById = async (productId) => {
        if (productId) {
            try {
                const data = await getProductById(productId);
                setProductForm({
                    name: data.name,
                    description: data.description,
                    price: data.price,
                    imageName: data.imageName,
                    categoryIdForProduct: data.categoryId,
                });
            } catch (error) {
                console.error("Error fetching Product:", error);
            }
        }
    };

    // Adding Categories
    const addCategory = async () => {
        await insertCategory(form);
        await emptyForm();
        await getCategories();
        setModalOpen(false);
    };

    // Update Category
    const editCategory = async () => {
        await updateCategory(categoryId, form);
        // await emptyForm();
        setModalOpen(false);
    };

    // Delete Category
    const removeCategory = async () => {
        await deleteCategory(categoryId);
        setModalOpen(false);
    };

    // Empty the data of the modal
    const justRemoveModal = async () => {
        await getCategories();
        // await emptyForm();
        setModalOpen(false);
    };

    // Adding Cattle
    const addCattle = async () => {
        await insertCattles(cattleForm);
        await emptyForm();
        setModalOpen(false);
    };

    // Delete Cattle
    const removeCattle = async () => {
        await deleteCattles(cattleId);
        await emptyForm();
        setModalOpen(false)
    }

    // Update Cattle
    const editCattle = async () => {
        await updateCattles(cattleForm, cattleId);
        await emptyForm();
        setModalOpen(false);
    }

    // Adding Product
    const addProduct = async () => {
        const formData = new FormData();
        formData.append("name", productForm.name);
        formData.append("description", productForm.description);
        formData.append("price", productForm.price);
        formData.append("file", selectedFile); // Use selectedFile instead of productForm.imageName
        formData.append("categoryId", productForm.categoryIdForProduct);

        await insertProduct(formData);
        await emptyForm();
        setModalOpen(false);
    };

    // Update Product
    const editProduct = async () => {
        const formData = new FormData();
        formData.append("name", productForm.name);
        formData.append("description", productForm.description);
        formData.append("price", productForm.price);
        formData.append("categoryId", productForm.categoryIdForProduct);

        if (selectedFile) {
            formData.append("file", selectedFile);
        } else {
            formData.append("imageName", productForm.imageName);
        }

        try {
            await updateProduct(formData, productId);
            await emptyForm();
            setModalOpen(false);
        } catch (error) {
            console.error("Error updating Product:", error);
        }
    };


    // Delete Product
    const removeProduct = async () => {
        await deleteProduct(productId);
        setModalOpen(false);
    }

    // Complete Order
    const completeOrder = async () => {
        await completedOrder(orderId);
        setModalOpen(false);
    }

    // Cancel Order
    const cancelOrder = async () => {
        await canceledProduct(orderId);
        setModalOpen(false);
    }

    useEffect(() => {
        fetchCategoryData();
        fetchCategoryAccId(categoryId);
        fetchProductAccById(productId);
        fetchCattleAccId(cattleId);
        emptyForm();
    }, [categoryId, productId, cattleId]);


    return (<>
        {title === "Cattle" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="grid gap-8 mb-6 md:grid-cols-7">
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Name
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Name"
                                    value={cattleForm.name}
                                    name="name"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Gender
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Gender"
                                    value={cattleForm.gender}
                                    name="gender"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Id Number
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle ID number"
                                    value={cattleForm.idNumber}
                                    name="idNumber"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Date Of Birth
                                </label>
                                <input
                                    type="date"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle DOB"
                                    value={cattleForm.dateOfBirth}
                                    name="dateOfBirth"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Weight
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Weight"
                                    value={cattleForm.weight}
                                    name="weight"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Height
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Height"
                                    value={cattleForm.height}
                                    name="height"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Length"
                                    value={cattleForm.length}
                                    name="length"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Color
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Color"
                                    value={cattleForm.color}
                                    name="color"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Tail Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Tail Length"
                                    value={cattleForm.tailLength}
                                    name="tailLength"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Horn Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Horn Length"
                                    value={cattleForm.hornLength}
                                    name="hornLength"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Ear Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Ear Length"
                                    value={cattleForm.earLength}
                                    name="earLength"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Teeth
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Teeth"
                                    value={cattleForm.teeth}
                                    name="teeth"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Eye Color
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Eye Color"
                                    value={cattleForm.eyeColor}
                                    name="eyeColor"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Udder Color
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Udder Color"
                                    value={cattleForm.udderColor}
                                    name="udderColor"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Milk Capacity in Liter
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Milk Capacity in Liter"
                                    value={cattleForm.milkCapacityInLiters}
                                    name="milkCapacityInLiters"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Delivery Data
                                </label>
                                <input
                                    type="date"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Delivery Data"
                                    value={cattleForm.deliveryDate}
                                    name="deliveryDate"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Mother Side History
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Mother Side History"
                                    value={cattleForm.motherSideHistory}
                                    name="motherSideHistory"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Father Side History
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Father Side History"
                                    value={cattleForm.fatherSideHistory}
                                    name="fatherSideHistory"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Behavior
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Behavior"
                                    value={cattleForm.behaviour}
                                    name="behaviour"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Habit of Eating
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Habit of Eating"
                                    value={cattleForm.habitOfEating}
                                    name="habitOfEating"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Nature
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Nature"
                                    value={cattleForm.nature}
                                    name="nature"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={addCattle}
                        >
                            <svg
                                className="h-5 w-5 -ms-1 me-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            Add new {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Delete Cattle" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Are you sure you want to delete cattle ???
                                </label>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={removeCattle}
                        >
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Update Cattle" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="grid gap-8 mb-6 md:grid-cols-7">
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Name
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Name"
                                    value={cattleForm.name}
                                    name="name"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Gender
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Gender"
                                    value={cattleForm.gender}
                                    name="gender"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Id Number
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle ID number"
                                    value={cattleForm.idNumber}
                                    name="idNumber"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Date Of Birth
                                </label>
                                <input
                                    type="date"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle DOB"
                                    value={cattleForm.dateOfBirth}
                                    name="dateOfBirth"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Weight
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Weight"
                                    value={cattleForm.weight}
                                    name="weight"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Height
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Height"
                                    value={cattleForm.height}
                                    name="height"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Length"
                                    value={cattleForm.length}
                                    name="length"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Color
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Color"
                                    value={cattleForm.color}
                                    name="color"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Tail Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Tail Length"
                                    value={cattleForm.tailLength}
                                    name="tailLength"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Horn Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Horn Length"
                                    value={cattleForm.hornLength}
                                    name="hornLength"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Ear Length
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Ear Length"
                                    value={cattleForm.earLength}
                                    name="earLength"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Teeth
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Teeth"
                                    value={cattleForm.teeth}
                                    name="teeth"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Eye Color
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Eye Color"
                                    value={cattleForm.eyeColor}
                                    name="eyeColor"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Udder Color
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Udder Color"
                                    value={cattleForm.udderColor}
                                    name="udderColor"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Milk Capacity in Liter
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Milk Capacity in Liter"
                                    value={cattleForm.milkCapacityInLiters}
                                    name="milkCapacityInLiters"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Delivery Data
                                </label>
                                <input
                                    type="date"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Delivery Data"
                                    value={cattleForm.deliveryDate}
                                    name="deliveryDate"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Mother Side History
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Mother Side History"
                                    value={cattleForm.motherSideHistory}
                                    name="motherSideHistory"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Father Side History
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Father Side History"
                                    value={cattleForm.fatherSideHistory}
                                    name="fatherSideHistory"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Behavior
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Behavior"
                                    value={cattleForm.behaviour}
                                    name="behaviour"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Habit of Eating
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Habit of Eating"
                                    value={cattleForm.habitOfEating}
                                    name="habitOfEating"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Cattle Nature
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Enter Cattle Nature"
                                    value={cattleForm.nature}
                                    name="nature"
                                    onChange={handleCattleInputChange}
                                />
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={editCattle}
                        >
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Category" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col-span-2">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Category Title
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Type Category Title"
                                    value={form.title}
                                    name="title"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-span-2">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Category Description
                                </label>
                                <textarea
                                    rows="4"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Write product description here"
                                    value={form.description}
                                    name="description"
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={addCategory}
                        >
                            <svg
                                className="h-5 w-5 -ms-1 me-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            Add new {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Update Category" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col-span-2">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Category Title
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Type Category Title"
                                    value={form.title}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-span-2">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Category Description
                                </label>
                                <textarea
                                    rows="4"
                                    name="description"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Write product description here"
                                    value={form.description}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={editCategory}
                        >
                            <svg
                                className="h-5 w-5 -ms-1 me-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Delete Category" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Are you sure you want to delete category ???
                                </label>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={removeCategory}
                        >
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Products" && (<div
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="w-full p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Product Title
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Product Title"
                                    required=""
                                    value={productForm.name}
                                    name="name"
                                    onChange={handleProductInputChange}
                                />
                            </div>
                            <div className="col">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Product Description
                                </label>
                                <textarea
                                    rows="4"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Product Description"
                                    value={productForm.description}
                                    name="description"
                                    onChange={handleProductInputChange}
                                ></textarea>
                            </div>
                            <div className="col">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Product Price
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Product Price"
                                    value={productForm.price}
                                    name="price"
                                    onChange={handleProductInputChange}
                                />
                            </div>
                            <div className="col">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Product Image
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Type Category Price"
                                    name="imageName"
                                    onChange={handleProductInputChange}
                                />
                            </div>
                            <div className="col sm:col-span-1">
                                <label
                                    htmlFor="category"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Category
                                </label>
                                <select
                                    name="categoryIdForProduct"
                                    onChange={handleProductInputChange}
                                    className="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                >
                                    <option defaultValue="">Select Category</option>
                                    {categories.map((category) => (<option key={category.id} value={category.id}>
                                        {category.title}
                                    </option>))}
                                </select>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={addProduct}
                        >
                            <svg
                                className="h-5 w-5 -ms-1 me-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            Add new {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Update Product" && (<div
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="w-full p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col-span-2">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Product Title
                                </label>
                                <input
                                    type="text"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Update Product Title"
                                    required=""
                                    value={productForm.name}
                                    name="name"
                                    onChange={handleProductInputChange}
                                />
                            </div>
                            <div className="col-span-2">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Product Description
                                </label>
                                <textarea
                                    rows="4"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Update Product Description"
                                    value={productForm.description}
                                    name="description"
                                    onChange={handleProductInputChange}
                                ></textarea>
                            </div>
                            <div className="col-span-2">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Product Price
                                </label>
                                <input
                                    type="number"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Product Price"
                                    value={productForm.price}
                                    name="price"
                                    onChange={handleProductInputChange}
                                />
                            </div>
                            <div className="col-span-2">
                                <label
                                    htmlFor="description"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Product Image
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                    placeholder="Type Category Price"
                                    name="imageName"
                                    onChange={handleProductInputChange}
                                />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label
                                    htmlFor="category"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Update Category
                                </label>
                                <select
                                    name="categoryIdForProduct"
                                    onChange={handleProductInputChange}
                                    className="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                                >
                                    <option defaultValue="">Select Category</option>
                                    {categories.map((category) => (<option key={category.id} value={category.id}>
                                        {category.title}
                                    </option>))}
                                </select>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={editProduct}
                        >
                            <svg
                                className="h-5 w-5 -ms-1 me-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Delete Product" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Are you sure you want to delete category ???
                                </label>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={removeProduct}
                        >
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Complete Order" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Are you sure you want to complete order ???
                                </label>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={completeOrder}
                        >
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
        {title === "Cancel Order" && (<div
            id="crud-modal"
            tabIndex="-1"
            className={`fixed inset-0 flex items-center justify-center z-50 ${modalOpen ? "" : "hidden"}`}
        >
            <div
                className="fixed inset-0 bg-black opacity-50"
                style={{background: "black"}}
                onClick={() => setModalOpen(false)} // Close modal on backdrop click
            ></div>
            <div className="relative max-h-full w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={justRemoveModal}
                        >
                            <svg
                                className="h-3 w-3"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 md:p-5">
                        <div className="mb-4 grid grid-cols-2 gap-4">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Are you sure you dont have this product ???
                                </label>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={cancelOrder}
                        >
                            {title}
                        </button>
                    </form>
                </div>
            </div>
        </div>)}
    </>);
};

export default Modal;
